import * as React from 'react';
import itLocale from 'date-fns/locale/it';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

export default function DataRangePicker(props) {
    
    return(
        <DateRange
        editableDateInputs={true}
        onChange={item => props.setState([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={props.state}
        locale={itLocale}
        color= {"#bbdefb"}
        />
    );
}

