import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';
import LoadingSpinner from "Components/LoadingSpinner";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateMonthPicker from "Components/DateMonthPicker";
import Typography from "@mui/material/Typography";

import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsReparto from "Utils/Datagrid/Reparto/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import KitchenIcon from "@mui/icons-material/Kitchen";
import { PieIncassoAggregatoData } from "Utils/Grafici/Reparto/IncassoAggregato";
import { getColors } from "Utils/Funzioni/Grafici";
import CardObiettivoSettore from "Components/CardObiettivoSettore";
import Myparticles from "Components/MyParticles";
ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Obiettivi",
      href: `/${idCliente}/Obiettivi`,
      icon: <KitchenIcon fontSize="small" />,
    },
    {
      label: "Settore",
      href: `/${idCliente}/Settore`,
      icon: <KitchenIcon fontSize="small" />,
    },
  ];
};

var server = configData.general_api_end_point;
const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function ObiettiviSettore() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [settoriMensili, setSettoriMensili] = React.useState([]);
  const [settoriAnnuali, setSettoriAnnuali] = React.useState([]);

  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([]);


  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.id_negozio,
            valore: negozio.nome_negozio,
          });
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setSettoriMensili([]);
    setSettoriAnnuali([]);
    console.log(getAllFilters())
    axios.all([
      axios.post(
        `${server}/${idCliente}/negozi/aggregati/settori/obiettivi`,
        getAllFilters()
      ),
      axios.post(
        `${server}/${idCliente}/negozi/aggregati/settori/obiettivi`,
        getAllFiltersAnnuale()
      )
    ])
      .then(function ([responseMensile, responseAnnuale]) {
        setSettoriMensili(responseMensile.data);
        setSettoriAnnuali(responseAnnuale.data);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };


  const scaricaExcel = () => {
    console.log("");
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: intervalloGiorni[0],
        dataA: intervalloGiorni[1],
        puntiVendita: filtroNegozi,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const getAllFiltersAnnuale = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `01/01/${moment().format('YYYY')}`,
        dataA: `31/12/${moment().format('YYYY')}`,
        puntiVendita: filtroNegozi,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const ObiettiviSettoreRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <LoadingSpinner open={caricamentoDati} />
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateMonthPicker
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8} direction="row">
                      <Grid item xl={4} md={4}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozi"}
                          selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={8}
                        md={8}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper style={{ marginBottom: "2vh" }}>
              <Tabs
                value={tabSelezionato}
                onChange={(event, newValue) => {
                  setTabSelezionato(newValue);
                }}
                variant="fullWidth"
                indicatorColor="[primary]"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab icon={<TocIcon />} label="Obiettivi Mensili" />
                <Tab icon={<TimelineIcon />} label="Obiettivi Annuali" />
              </Tabs>
            </Paper>
            <React.Fragment>
              {tabSelezionato === 0 ? (
                <Grid container direction="row" spacing={2}>
                  {
                    settoriMensili.length != 0 ? 
                  Object.entries(settoriMensili).map((row, index) => {
                    console.log(row[1]);
                    const settore = row[1];
                    return (
                      <Grid item xl={6}>
                        <CardObiettivoSettore
                          extended={true}
                          datiSettore={settore}
                        ></CardObiettivoSettore>
                      </Grid>
                    );
                  })
                  : <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                    justifyItems: "center"
                  }}
                ><Typography variant="h6" style={{margin:"auto"}}> Nessun record </Typography></Paper>
                }
                </Grid>
              ) : (
                <Grid container direction="row" spacing={2}>
                  {
                    settoriAnnuali.length != 0 ?
                  Object.entries(settoriAnnuali).map((row, index) => {
                    console.log(row[1]);
                    const settore = row[1];
                    return (      
                      <Grid item xl={6}>
                        <CardObiettivoSettore
                          extended={true}
                          datiSettore={settore}
                        ></CardObiettivoSettore>
                      </Grid>
                    );
                  })
                  : <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                    justifyItems: "center"
                  }}
                ><Typography variant="h6" style={{margin:"auto"}}> Nessun record </Typography></Paper>
                }
                </Grid>
              )}
            </React.Fragment>
          </Container>
          <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {ObiettiviSettoreRendered()} </>;
}

export default ObiettiviSettore;
