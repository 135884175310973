import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import columnsDettaglioIva from "Utils/Datagrid/Finanziario/Iva/DettaglioIva";
import NavBar from "Components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { Typography } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";

import configData from "CONFIGURAZIONE.json";
var server = configData.general_api_end_point;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "80vw",
  },
}));

function DettaglioReparto(props) {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  const [data, setData] = React.useState(props.data);
  const [columns] = React.useState(columnsDettaglioIva);

  useEffect(()  => {
      // Calcolo incidenza
      let totale = data.reduce((previous, current) => { return parseFloat(previous) + parseFloat(current.Venduto)},0)
      for (let i = 0; i < data.length; i++) {
        data[i].Incidenza = parseFloat(data[i].Venduto) / totale *100
        console.log(data[i].Incidenza);
      } 
      setData(data);
    }
    ,[]);
  const RepartoRendered = (data) => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          
        >
            <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.data}
              rows={data}
              columns={columns}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />
        </Grid>
      </div>
    );
  };

  return <>{RepartoRendered(props.data)}</>;
}

export default DettaglioReparto;
