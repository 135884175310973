import Moment from 'moment';

export const columnsDettaglioReparto = [
    
  {
    field: 'negozio',
    headerName: 'Negozio',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'importoTotale',
    headerName: 'Totale',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
  },
  {
    field: 'nrArticoliDistinti',
    headerName: 'Articoli Distinti',
    width: 200,
    editable: false,
    type:"number",
    valueFormatter: ({ value }) => parseInt(value),
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'pesoTotale',
    headerName: 'Peso Totale',
    width: 200,
    editable: false,
    type:"number",
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'pezziTotali',
    headerName: 'Pezzi Totali',
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => parseInt(value),
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'nrScontrini',
    headerName: 'Nr. Scontrini',
    width: 200,
    valueFormatter: ({ value }) => parseInt(value),
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'nrBattute',
    headerName: 'Nr. Battute',
    width: 200,
    valueFormatter: ({ value }) => parseInt(value),
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  }
];