import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";
import ListItemText from "@material-ui/core/ListItemText"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsIncassoOperatore from "Utils/Datagrid/Operatore/IncassoOperatore";
import Diversity3Icon from "@mui/icons-material/Diversity3";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Line, Bar, Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RestorePageOutlined } from "@material-ui/icons";
import { Message, RouteSharp } from "@mui/icons-material";
import Box from "@mui/material/Box";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import { getColors } from "Utils/Funzioni/Grafici";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Operatore",
      href: `#`,
      icon: <PersonSearchIcon fontSize="small" />,
    },
  ];
};

var server = configData.general_api_end_point;

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
    flexGrow: 1,
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function ImpostazioniOperatore() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [flagSwitch, setFlagSwitch] = React.useState(false);
  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsIncassoOperatore);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  let { idCliente } = useParams();


  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  ////////////////////////////////////////////////////
  // FILTRI

  ////////////////////////////////////////////////////
  // Operatore
  const [filtroOperatori, setFiltroOperatori] = useState([]);
  const [idOperatori, setIdOperatori] = useState([]);

  ////

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getOperatori = () => {
    axios.get(`${server}/${idCliente}/operatori`).then((response) => {
      let negozi = [];
      setIdOperatori([]);
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.codice,
            valore: negozio.descrizione,
          });
        });
        setIdOperatori(negozi);
      }
    });
  }

  useEffect(() => {
    setIdOperatori([]);
    getOperatori();
  }, []);

  useEffect(() => {
    if (filtroOperatori.length === 0){return;}
    const found = idOperatori.find(element => element.chiave == filtroOperatori);
    console.log(found)
    setDescrizioneOperatore(found.valore)
    setCodiceOperatore(found.chiave)
  }, [filtroOperatori]);

  const [descrizioneOperatore, setDescrizioneOperatore] = useState("");
  const [codiceOperatore, setCodiceOperatore] = useState("");

  //"${server}/scontrini/0255/cercaPerData",
  const sendVariazione = () => {
    aggiornaCaricamento(true);
    setData({});
    axios
      .put(
        `${server}/${idCliente}/operatori/${filtroOperatori}`,
        {
          "descrizione":descrizioneOperatore
        }
      )
      .then(function (response) {
        console.log(flagSwitch)
        window.alert("Ok.")
        setIdOperatori([]);
        getOperatori();
      })
      .catch(function (error) {
        getOperatori();
        window.alert(error)
        console.log(error);
      });
  };


  const ImpostazioniOperatoreRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <Container className={classes.container} maxWidth="xl">
            {/* <Tabs
              value={tab}
              onChange={handleTabChange}
              centered
              aria-label="icon label tabs example"
            >
              <Tab icon={<ManageAccountsIcon />} label="Modifica Operatori" />
              <Tab
                icon={<PermContactCalendarIcon />} disabled
                label="Visualizza Operatori"
              />
            </Tabs> */}
              {/* <TabPanel value={tab} index={0}> */}
                  <Grid container spacing={2}>
                    <Grid item columns={4}>
                    <FormControl fullWidth style={{width:"20vw"}}>
                        <InputLabel id="demo-simple-select-label">
                          Operatori
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={filtroOperatori}
                          label="Cassa"
                          fullWidth
                          onChange={(event) => {
                            setFiltroOperatori(event.target.value);
                          }}
                        >
                          {idOperatori.map((item) => {
                            return <MenuItem value={item.chiave}>{item.chiave} - {item.valore}</MenuItem>;
                          }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item columns={4}>
                    <TextField label={"Codice Operatore"} inputProps={{ type: "string" }} value={codiceOperatore} disabled></TextField>
                    <TextField label={"Descrizione Operatore"} inputProps={{ type: "string" }} value={descrizioneOperatore} onChange={(event)=> setDescrizioneOperatore(event.target.value)}></TextField>
                    </Grid>
                    <Grid item columns={4}>
                    <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            sendVariazione();
                          }}
                        >
                          Salva
                        </Button>
                    </Grid>
                  </Grid>
                
              {/* </TabPanel> */}
          </Container>
          
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {ImpostazioniOperatoreRendered()} </>;
}

export default ImpostazioniOperatore;
