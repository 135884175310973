import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";
import LoadingSpinner from "Components/LoadingSpinner";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";

import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import columnsVenditeFasceOrario from "Utils/Datagrid/Fasce Orario/Vendite/VenditeFasceOrario";
import {getSliceOrari, getIntervalliOrari, getOrarioDa} from "Utils/Funzioni/Tempo"
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HomeIcon from "@material-ui/icons/Home";
import DettaglioVenditeFasceOrario from "./PopUp/DettaglioVenditeFasceOrario";
import Myparticles from "Components/MyParticles";
const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Fasce Orario",
      href: `/${idCliente}/Report/FasceOrarie`,
      icon: <AccessTimeIcon fontSize="small" />,
    }
  ];
};
// Configurazione app
var server = configData.general_api_end_point;
const moment = extendMoment(Moment);

// Stile Pagina
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function VenditeFasceOrario() { 
  
  // Gestione layout
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Gestione dati da back-end
  const [idNegozi, setIdNegozi] = useState([]);
  const [columns, _] = React.useState(columnsVenditeFasceOrario);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.fasceOrarioVendite;

  // Stati per gestione PopUp
  const [open, setOpen] = React.useState(false);
  const [interalloSpecifico, setIntervalloSpecifico] = useState({});
  
  // FILTRI
  const [filtroNegozi, setFiltroNegozi] = useState([]);
  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);
  const [filtroOraDa, setFiltroOraDa] = useState();
  const [filtroOraA, setFiltroOraA] = useState();
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [filtroDurataIntervallo, setFiltroDurataIntervallo] = useState();

  //  Popolamento campi di selezione
  const [intervalli, setIntervalli] = useState(() => getIntervalliOrari());
  const [orariDisponibiliDa, setOrariDisponibiliDa] = useState(() =>
    getOrarioDa()
  );
  const [orariDisponibiliA, setOrariDisponibiliA] = useState([]);

  

  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({"chiave":negozio.id_negozio, "valore":negozio.nome_negozio});
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  useEffect(() => {
    if (filtroDurataIntervallo === undefined) {
      return;
    }
    let sliceOre = getSliceOrari(
      filtroOraDa,
      filtroDurataIntervallo["stepOre"],
      filtroDurataIntervallo["stepMinuti"]
    );
    let sliceOreFormattato = sliceOre.map((sliceOra) => {
      return sliceOra.format("HH:mm");
    });
    setOrariDisponibiliA(sliceOreFormattato);
  }, [filtroDurataIntervallo]);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios.post(
        `${server}/${idCliente}/scontrini/aggregati/intervalli`,
        getAllFilters()
      )
      .then(function (response) {
        let rows = [];
        let key = 0;
        console.log(response.data);
        for (let idx in response.data) {
          console.log(response.data[idx]);
          rows.push(response.data[idx]);
        }
        setData(rows);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/scontrini/aggregati/intervalli/excel`, //your url
      method: 'POST',
      responseType: 'blob', // important
      data: getAllFilters()
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        aggiornaCaricamento(false);
        link.setAttribute('download', 'report.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
    
  };

  const getAllFilters = () => {
    let filter = {}
    try{
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        oraDa: `${filtroOraDa}:00`,
        oraA: filtroOraA,
        durataIntervallo: filtroDurataIntervallo.chiave,
        puntiVendita: filtroNegozi
      };
    }
    catch{
    
    }
    console.log(filter)
    return filter;
  };

  const VenditeFasceOrarioRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)} />
          <LoadingSpinner open={caricamentoDati}/>
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={12} md={12}>
                        <MultipleSelectCheckmarks label={"Selezione Negozi"} selezioneMultipla={true} items={idNegozi} setState={setFiltroNegozi}/>
                      </Grid>
                      <Grid container spacing={2} item xl={12} md={12}>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Dalle Ore
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroOraDa}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroOraDa(event.target.value);
                              }}
                            >
                              {orariDisponibiliDa.map((orario) => {
                                return (
                                  <MenuItem value={orario.valore}>
                                    {orario.chiave}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Durata intervallo
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroDurataIntervallo}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroDurataIntervallo(event.target.value);
                              }}
                            >
                              {intervalli.map((intervallo) => {
                                return (
                                  <MenuItem value={intervallo}>
                                    {intervallo["chiave"]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xl={4} md={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Alle Ore
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtroOraA}
                              label="Cassa"
                              onChange={(event) => {
                                setFiltroOraA(event.target.value);
                              }}
                            >
                              {orariDisponibiliA.map((orario) => {
                                return (
                                  <MenuItem value={orario}>{orario}</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          disabled={!configurazioneReport.visualizzaReportButton.attivo}
                        >
                          {configurazioneReport.visualizzaReportButton.buttonLabel}
                        </Button>
                        <Button
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                scaricaExcel();
                              }}
                              style={{
                                margin: "auto",
                              }}
                              disabled={!configurazioneReport.visualizzaReportButton.attivo}
                              >
                                {configurazioneReport.scaricaExcelButton.buttonLabel}
                            </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper>
                <DataGrid
                  style={{ flexGrow: 1, height:"75vh" }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  getRowId={(row) => row.inizio_intervallo}
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick
                  localeText={itIT.props.MuiDataGrid.localeText}
                  loading={caricamentoDati}
                  onRowDoubleClick={(event) => {setOpen(true); setIntervalloSpecifico({"inizio":event.row.inizio_intervallo,"fine":event.row.fine_intervallo})}}
                />
                 </Paper>
          </Container>
          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioVenditeFasceOrario
                  intervallo={interalloSpecifico}
                  negozi={filtroNegozi}
                  durataIntervallo={filtroDurataIntervallo}
                  intervalloGiorni={intervalloGiorni}
                  idCliente={idCliente}
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
          <Myparticles numberAtoms={60}/>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };

  return <> {VenditeFasceOrarioRendered()} </>;
}

export default VenditeFasceOrario;
