import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import columnsCercaArticolo from "Utils/Datagrid/Articolo/VendutoGiornaliero/CercaArticolo";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import configData from "CONFIGURAZIONE.json";
var server = configData.general_general_api_end_point;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "70vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "70vw",
  },
}));

function CercaArticolo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = React.useState([]);
  const [columns] = React.useState(columnsCercaArticolo);
  const [aggiornaCaricamento, setAggiornaCaricamento] = React.useState(false)
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;

  useEffect(() => {
      setAggiornaCaricamento(true);
      setData([]);
      axios
        .post(`${server}/${idCliente}/articoli/cerca/descrizione`, getAllFilters())
        .then(function (response) {
          setData(response.data);
          setAggiornaCaricamento(false);
        })
        .catch(function (error) {
          setData([]);
          setAggiornaCaricamento(false);
          console.log(error);
        });
  }, []);


  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: props.informazioni.dataDa,
        dataA: props.informazioni.dataA,
        puntiVendita: props.informazioni.puntiVendita,
        descrizioneArticolo: props.informazioni.descrizioneArticolo
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const CercaArticoloRendered = () => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          
        >
            <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              loading={aggiornaCaricamento}
              getRowId={(row) => `${row.ean},${row.cda}`}s
              rows={data}
              columns={columns}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
              onRowDoubleClick={(event) => {
                props.informazioni.callBackSetEan(event.row.ean);
                props.informazioni.callBackSetCodiceArticolo(event.row.cda);
                props.informazioni.callBackSetDescrizioneArticolo(event.row.descrizione);
                props.informazioni.callBackChiudi(false)
              }}
            />
        </Grid>
      </div>
    );
  };

  return <>{CercaArticoloRendered(props.informazioni)}</>;
}

export default CercaArticolo;
