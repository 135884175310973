import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "Components/LoadingSpinner";
import PinInput from "react-pin-input";
import configData from "../CONFIGURAZIONE.json";
import { createContext, useContext, useState } from 'react';
import { LicenzaContext } from '../Utils/LicenzaContext';
import { useCallback } from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Collapse } from "@material-ui/core";

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
var server = configData.general_api_end_point;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.sistemipos.it/">
        Sistemi Pos SRL
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function LogIn() {
  let history = useHistory();

  const licenzaContext = useContext(LicenzaContext);
  const [cliente, setCliente] = React.useState("");
  const [utente, setUtente] = React.useState("");
  const [apriSpinner, setApriSpinner] = React.useState(false);
  const [showLoginError, setShowLoginError] = React.useState(false);
  const [pinFocus, setPinFocus] = React.useState(false);
  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

  const handleComplete = (value) => {
    // eslint-disable-next-line no-console
    const body_param = {
      cliente: cliente,
      utente: value,
    };

    setApriSpinner(true);
    axios
      .post(`${server}/login`, body_param)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          licenzaContext.setLicenza(response.data);
          history.replace(`/${response.data.id_cliente}`);
        }
        setApriSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setPinFocus(true);
        setApriSpinner(false);
        setShowLoginError(true);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <LoadingSpinner open={apriSpinner} />

      <Grid container component="main" sx={{
        width: "100vw", height: "100vh", backgroundImage:
          "url(https://images2.alphacoders.com/459/459009.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }} justifySelf="center" alignSelf="center" justifyContent="center" alignContent="center">
        <CssBaseline />
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://wallpapers.com/images/hd/material-design-1920-x-1080-background-btkskjckokr7sk2a.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        /> */}
        <Grid item xs={6} sm={5} md={5} component={Paper} sx={{ opacity: 0.92, backgroundColor: (t) => t.palette.grey[100] }} elevation={6} square justifySelf="center" alignSelf="center" justifyContent="center" alignContent="center">
          <Collapse in={showLoginError}>
              <Alert severity="error" action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowLoginError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              >
                <AlertTitle>Errore</AlertTitle>
                Codice Cliente o PIN errati
              </Alert>
          </Collapse>
          <Box
            sx={{
              my: 3,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              opacity: 1
            }}
          >
            <Avatar
              sx={{
                m: 4,
                bgcolor: "primary.main",
                width: "6vw",
                height: "6vw",
                opacity: 1
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              My Pos Web
            </Typography>
            <div style={{ height: "5vh" }}></div>
            <Typography component="h1" variant="h5">- Inserisci il Codice Cliente -</Typography>
            <PinInput
              focus
              length={3}
              secret={false}
              onChange={(value, index) => { }}
              type="numeric"
              style={{ padding: "10px" }}
              inputStyle={{
                caretColor: "transparent",
                width: 50,
                height: 50,
                borderRadius: "50%",
                border: "3px solid red",
                outline: "none",
                textAlign: "center",
                margin: 10,
                opacity: 1
              }}
              inputFocusStyle={{ borderColor: "blu" }}
              onComplete={(value, index) => {
                setCliente(value);
              }}
            />
            <Typography component="h1" variant="h5">- Inserisci il PIN -</Typography>
            <PinInput
              length={5}
              secret
              secretDelay={1000}
              focus={pinFocus}
              onChange={(value, index) => { }}
              type="numeric"
              style={{ padding: "10px" }}
              inputStyle={{
                caretColor: "transparent",
                width: 50,
                height: 50,
                borderRadius: "50%",
                border: "3px solid #5e07a0",
                outline: "none",
                textAlign: "center",
                margin: 10,
                opacity: 1
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {
                handleComplete(value);
              }}
            />
            <div style={{ height: "8vh" }}></div>
            <Copyright sx={{ mt: 5 }} />
            <Typography component="h7" variant="h7">
              v. 6.0.0
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
