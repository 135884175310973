import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? "#e0e0e0"
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.info.dark,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomizedBreadcrumbs(props) {
    let history = useHistory();
    return (
      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "4vw",width:"30vw"}}>
        {
            props.items.map((item) => {
                return (<StyledBreadcrumb
                component="a"
                onClick={() => {history.push(item.href);}} 
                label={item.label}
                icon={item.icon}
                style={{color: "#004d40"}}
                />)
            })
        }
      </Breadcrumbs>
  );
}
