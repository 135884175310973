import { Line, Bar, Pie } from "react-chartjs-2";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Info from "@mui/icons-material/Info";
import Moment from "moment";
import LoadingSpinner from "Components/LoadingSpinner";
import Anime from 'react-anime';
import {
  ScontriniMediData,
  ScontriniMediOptions,
} from "Utils/Grafici/Home/ScontriniMedi";

import {
  PresenzeRepartiGiornalieroData,
  PresenzeRepartiGiornalieroOptions,
} from "Utils/Grafici/Home/PresenzeRepartiGiornaliero";
import { getColors, getColor } from "Utils/Funzioni/Grafici";
import {
  TenderGiornalieriData,
  TenderGiornalieriOptions,
} from "Utils/Grafici/Home/TenderGiornalieri";

import {
  IncassoGiornalieroData,
  IncassoGiornalieroOptions,
} from "Utils/Grafici/Home/IncassoGiornaliero";

import {
  IncassoMedioScontriniData,
  IncassoMedioScontriniOptions,
} from "Utils/Grafici/Home/IncassoMedioScontrini";

import {
  PresenzeRepartiAggregatoOptions,
  PresenzeRepartiAggregatoData,
} from "Utils/Grafici/Home/PresenzeRepartiAggregato";

import {
  VenditeAnnualiOptions,
  VenditeAnnualiData,
} from "Utils/Grafici/Home/VenditeAnnuali";

import {
  VenditeSettimanaliOptions,
  VenditeSettimanaliData,
} from "Utils/Grafici/Home/VenditeSettimanali";

import {
  PresenzeSettimanaliOptions,
  PresenzeSettimanaliData,
} from "Utils/Grafici/Home/PresenzeSettimanali";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import Navbar from "Components/NavBar";
import { DataArraySharp } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
// Pilota per API
import configData from "CONFIGURAZIONE.json";
import { useCallback } from "react";
import CardObiettivoSettore from "Components/CardObiettivoSettore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { createContext, useContext } from "react";
import { LicenzaContext } from "Utils/LicenzaContext";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsReparto from "Utils/Datagrid/Home/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import Myparticles from "Components/MyParticles";
import StarIcon from "@material-ui/icons/Star";

var server = configData.general_api_end_point;

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
  ];
};
// Definizione stile
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  container: {
    padding: theme.spacing(2),
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  chart: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      height: "50vh",
    },
  },
  chart2: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
      height: "80vh",
    },
  },
}));

function VenditeHome() {
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.Home;
  let history = useHistory();
  // Stile
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  // Semafori apertura/chiusura grafici
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // Contenitori dei dati
  const [incassoGiornalieroChart, setIncassoGiornalieroChart] = useState({});
  const [scontriniMedioChart, setScontrinoMedioChart] = useState({});
  const [tenderGiornalieriChart, setTenderGiornalieriChart] = useState({});
  const [presenzeRepartiGiornalieroChart, setPresenzeRepartiGiornaliero] =
    useState({});
  const [presenzeRepartiAggregatoChart, setPresenzeRepartiAggregato] = useState(
    {}
  );
  const [venditeAnnualiChart, setVenditeAnnualiChart] = useState({});
  const [venditeSettimanaliChart, setVenditeSettimanaliChart] = useState({});
  const [presenzeSettimanaliChart, setPresenzeSettimanaliChart] = useState({});
  const [incassoMedioScontriniChart, setIncassoMedioScontriniChart] = useState(
    {}
  );
  const [settimanale, setSettimanale] = useState([]);
  const [settimanalePrecedente, setSettimanalePrecedente] = useState([]);
  const [settimanaleAnnoPrecedente, setSettimanaleAnnoPrecedente] = useState(
    []
  );

  const [settori, setSettori] = useState([]);
  //Informazioni negozio
  const [idNegozi, setIdNegozi] = useState([]);
  const [idNegozio, setIdNegozio] = useState("");
  const [clickedElement, setClickedElement] = useState("");
  const [apriSpinner, setApriSpinner] = useState(false);
  // Funzioni //
  const [columns, setColumns] = React.useState(columnsReparto);
  const [dataPresenzeReparti, setPresenzeReparti] = React.useState([]);
  // Fun. Semafori apertura/chiusura grafici
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [analisiSettimanaleTabValue, setAnalisiSettimanaleTabValue] =
    React.useState(0);

  const formatter = new Intl.NumberFormat("it-IT", {
    maximumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  // Cambio negozio
  const handleIdNegozioChange = (event) => {
    setIdNegozio(event.target.value);
  };

  // Fun. Caricamento Negozi del cliente
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status === 200) {
        response.data.forEach((negozio) => {
          if (configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) || configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0){
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
            });
        }
        });
        setIdNegozi(negozi);
        setIdNegozio(negozi[0].chiave);
      }
    });
  }, [idCliente]);

  useEffect(() => {
    setSettori([]);
    // primo giorno del mese corrente
    const dataDa = Moment().startOf("month").format("DD/MM/YYYY");

    // ultimo giorno del mese corrente
    const dataA = Moment().endOf("month").format("DD/MM/YYYY");
    axios
      .post(`${server}/${idCliente}/negozi/aggregati/settori/obiettivi`, {
        // dataDa: dataDa.format("DD/MM/YYYY"),
        // dataA: dataA.format("DD/MM/YYYY"),
        dataDa: dataDa,
        dataA: dataA,
        puntiVendita: [idNegozio],
      })
      .then(function (response) {
        setSettori(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idNegozio, idCliente]);

  // Fun. Caricamento dei Dati
  useEffect(() => {
    setApriSpinner(true);
    var dataDa = Moment();
    dataDa = dataDa.subtract(1, "months");
    var dataA = Moment();
    dataA = dataA.subtract(1, "days");
    const body_param = {
      dataDa: dataDa.format("DD/MM/YYYY"),
      dataA: dataA.format("DD/MM/YYYY"),
    };
    setShow(false);
    axios
      .all([
        axios.post(
          `${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati`,
          body_param
        ),
        axios.post(
          `${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati/annuale`,
          body_param
        ),
        axios.post(
          `${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati/settimanale`,
          body_param
        ),
      ])
      .then(
        axios.spread((response1, response2, response3) => {
          console.log(response1.data);
          console.log(response2.data);
          let vendutoAnnuale = response2.data;
          let venduto = response1.data;
          let numero_scontrini = [];
          let numero_scontrini_non_fidelity = [];
          let numero_scontrini_fidelity = [];
          let incasso_giornaliero = [];
          let giornoAnno = [];
          let tenders = {};
          let reparti = {};
          let aggregatoReparti = {};
          let aggregatoRepartiTabella = [];
          let arrayColoreReparti = [];
          for (const [, data] of Object.entries(venduto)) {
            numero_scontrini.push(data["nr_scontrini"]);
            numero_scontrini_non_fidelity.push(
              data["nr_scontrini"] - data["nr_scontrini_fidelity"]
            );
            numero_scontrini_fidelity.push(data["nr_scontrini_fidelity"]);
            incasso_giornaliero.push(data["importo"]);
            giornoAnno.push(Moment(data["data"]).format("DD/MM/YYYY"));
            console.log(data);
            // Creo tutti i vettori che mi servono per gestire i vari tender di pagamento
            for (const [, tender] of Object.entries(
              data["totali_per_tender"]
            )) {
              if (!(tender.Descrizione in tenders)) {
                tenders[tender.Descrizione] = [];
              }
              tenders[tender.Descrizione].push({
                x: Moment(data["data"]).format("DD/MM/YYYY"),
                y: tender.Totale,
              });
            }

            // Creo tutti i vettori che mi servono per le vendite annuali
            let venditeAnnoAttuale = [];
            let venditeAnnoPrecedente = [];
            for (const [, mese] of Object.entries(
              vendutoAnnuale["annoAttuale"]
            )) {
              venditeAnnoAttuale.push(mese["importo"]);
            }
            for (const [, mese] of Object.entries(
              vendutoAnnuale["annoPrecedente"]
            )) {
              venditeAnnoPrecedente.push(mese["importo"]);
            }

            VenditeAnnualiData.datasets[0].data = venditeAnnoAttuale;
            VenditeAnnualiData.datasets[1].data = venditeAnnoPrecedente;

            // Creo tutti i vettori che mi servono per le vendite settimanali
            let venditeSettimanaliAttuale = [];
            let venditeSettimanaliPrecedente = [];
            let venditeSettimanaliAnnoPrecedente = [];
            let labelsSettimanale = [];

            for (const [, giorno] of Object.entries(
              response3.data.settimanaAttuale
            )) {
              venditeSettimanaliAttuale.push(giorno["importo"]);
              labelsSettimanale.push(giorno["giorno"]);
            }
            for (const [, giorno] of Object.entries(
              response3.data.settimanaPrecedente
            )) {
              venditeSettimanaliPrecedente.push(giorno["importo"]);
            }
            for (const [, giorno] of Object.entries(
              response3.data.settimanaAnnoPrecedente
            )) {
              venditeSettimanaliAnnoPrecedente.push(giorno["importo"]);
            }

            VenditeSettimanaliData.labels = labelsSettimanale;
            VenditeSettimanaliData.datasets[0].data = venditeSettimanaliAttuale;
            VenditeSettimanaliData.datasets[1].data =
              venditeSettimanaliPrecedente;
            VenditeSettimanaliData.datasets[2].data =
              venditeSettimanaliAnnoPrecedente;

            // Creo tutti i vettori che mi servono per le presenze settimanali
            let presenzeSettimanaliAttuale = [];
            let presenzeSettimanaliPrecedente = [];
            let presenzeSettimanaliAnnoPrecedente = [];

            for (const [, giorno] of Object.entries(
              response3.data.settimanaAttuale
            )) {
              presenzeSettimanaliAttuale.push(giorno["nr_scontrini"]);
            }
            for (const [, giorno] of Object.entries(
              response3.data.settimanaPrecedente
            )) {
              presenzeSettimanaliPrecedente.push(giorno["nr_scontrini"]);
            }
            for (const [, giorno] of Object.entries(
              response3.data.settimanaAnnoPrecedente
            )) {
              presenzeSettimanaliAnnoPrecedente.push(giorno["nr_scontrini"]);
            }

            PresenzeSettimanaliData.labels = labelsSettimanale;
            PresenzeSettimanaliData.datasets[0].data =
              presenzeSettimanaliAttuale;
            PresenzeSettimanaliData.datasets[1].data =
              presenzeSettimanaliPrecedente;
            PresenzeSettimanaliData.datasets[2].data =
              presenzeSettimanaliAnnoPrecedente;

            // Creo tutti i vettori che mi servono per gestire i vari reparti
            for (const [, reparto] of Object.entries(
              data["importi_presenze_reparti"]
            )) {
              if (!(reparto.Descrizione in reparti)) {
                reparti[reparto.Descrizione] = [];
              }
              reparti[reparto.Descrizione].push({
                x: data["data"],
                y: reparto.Presenze,
              });
            }
          }

          PresenzeRepartiGiornalieroData.datasets = [];
          let i = 0;
          for (const [reparto, datiReparto] of Object.entries(reparti)) {
            // Aggregatore Presenze Reparti
            // itero per le presenze su ogni giorno
            aggregatoReparti[reparto] = 0;
            for (const [, presenza] of Object.entries(datiReparto)) {
              aggregatoReparti[reparto] += parseInt(presenza.y); //y rappresenta il dato riferente alle presenze
            }
            aggregatoRepartiTabella.push({
              codice: i,
              descrizione: reparto,
              presenze: aggregatoReparti[reparto],
            });

            const colore = getColor(i);
            arrayColoreReparti.push(colore);
            PresenzeRepartiGiornalieroData.datasets.push({
              type: "bar",
              stack: "Base",
              label: reparto,
              data: datiReparto,
              backgroundColor: colore,
              borderColor: "white",
            });
            i++;
          }

          TenderGiornalieriData.datasets = [];
          i = 0;
          for (const [descrizione, dati] of Object.entries(tenders)) {
            TenderGiornalieriData.datasets.push({
              type: "bar",
              stack: "Base",
              label: descrizione,
              data: dati,
              backgroundColor: getColor(i, 0.6),
              borderColor: "white",

              order: 2,
            });
            i++;
          }

          TenderGiornalieriData.datasets.push({
            type: "line",
            fill: false,
            label: "Incasso Giornaliero",
            data: incasso_giornaliero,
            backgroundColor: getColor(100),
            borderColor: getColor(70),
            order: 1,
          });

          PresenzeRepartiAggregatoData.labels = Object.keys(aggregatoReparti);
          PresenzeRepartiAggregatoData.datasets[0].data =
            Object.values(aggregatoReparti);
          PresenzeRepartiAggregatoData.datasets[0].backgroundColor = getColors(
            aggregatoReparti.length
          );

          ScontriniMediData.labels = giornoAnno;
          ScontriniMediData.datasets[0].data = numero_scontrini_non_fidelity;
          ScontriniMediData.datasets[1].data = numero_scontrini_fidelity;

          IncassoGiornalieroData.labels = giornoAnno;
          IncassoGiornalieroData.datasets[0].data = incasso_giornaliero;

          IncassoMedioScontriniData.labels = giornoAnno;
          let mediaScontrini = incasso_giornaliero.map(
            (incasso, index) => incasso / numero_scontrini[index]
          );
          IncassoMedioScontriniData.datasets[0].data = mediaScontrini;
          IncassoMedioScontriniData.datasets[1].data = numero_scontrini;

          console.log(response3.data);
          setPresenzeSettimanaliChart(PresenzeSettimanaliData);
          setVenditeSettimanaliChart(VenditeSettimanaliData);
          setSettimanale(response3.data.settimanaAttuale);
          setSettimanalePrecedente(response3.data.settimanaPrecedente);
          setSettimanaleAnnoPrecedente(response3.data.settimanaAnnoPrecedente);
          setVenditeAnnualiChart(VenditeAnnualiData);
          setPresenzeRepartiAggregato(PresenzeRepartiAggregatoData);
          setIncassoMedioScontriniChart(IncassoMedioScontriniData);
          setIncassoGiornalieroChart(IncassoGiornalieroData);
          setScontrinoMedioChart(ScontriniMediData);
          setTenderGiornalieriChart(TenderGiornalieriData);
          setPresenzeRepartiGiornaliero(PresenzeRepartiGiornalieroData);
          console.log(aggregatoRepartiTabella);
          setPresenzeReparti(aggregatoRepartiTabella);
          setApriSpinner(false);
          setShow(true);
        })
      )
      .catch(function (error) {
        setApriSpinner(false);
        console.log(error);
      });
  }, [idNegozio, idCliente]);

  // Render //

  // Render condizionale
  const HomeRendered = () => {
    if (desktop | tablet)
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            marginTop: '3vw',
            filter: !configurazioneReport.attivo ? 'blur(20px)' : "",
            pointerEvents: !configurazioneReport.attivo ? 'none' : "",
          }}
        >

          <Grid item xl={2} md={2} className={classes.container}>
            <Anime easing="easeOutElastic"
              duration={2000}
              direction="alternate"
              loop={true}
              delay={(el, index) => index * 240}
              scale={[.8, .9]}>
              <Paper elevation={6}>

                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={idNegozio}
                  onChange={handleIdNegozioChange}
                  fullWidth
                  disabled={configurazioneReport.negoziSelectBox.attivo}
                  label="Seleziona Negozio"
                >
                  <MenuItem value="0000">Nessuno</MenuItem>
                  {idNegozi.map((negozio) => {
                    return (
                      <MenuItem value={negozio.chiave}>
                        {negozio.valore}
                      </MenuItem>
                    );
                  })}
                </Select>

              </Paper>
            </Anime>
          </Grid>

          <Grid item xl={12} md={12} className={classes.container}>
            <Container>

              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Incasso/Tender Giornaliero
                </Typography>
                <Paper elevation={5} className={classes.paper}>
                  {show ? (
                    <Bar
                      data={tenderGiornalieriChart}
                      options={TenderGiornalieriOptions}
                      className={classes.chart}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          <Grid item xl={6} md={6} className={classes.container}>
            <Container>
              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Rapporto Scontrini/Fidelity
                </Typography>
                <Paper elevation={3} className={classes.paper}>
                  {show ? (
                    <Bar
                      data={scontriniMedioChart}
                      options={ScontriniMediOptions}
                      className={classes.chart}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          <Grid item xl={6} md={6} className={classes.container}>
            <Container>
              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Val.Medio Scont. / Nr. Scontrini
                </Typography>
                <Paper elevation={3} className={classes.paper}>
                  {show ? (
                    <Bar
                      data={incassoMedioScontriniChart}
                      options={IncassoMedioScontriniOptions}
                      className={classes.chart}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          <Grid
            container
            xl={12}
            md={12}
            className={classes.container}
            direction="row"
          >
            <Grid item xl={6} md={6}>
              <Container>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Importo Annuale
                  </Typography>
                  <Paper elevation={3} style={{ padding: "1vw" }}>
                    {show ? (
                      <Line
                        options={VenditeAnnualiOptions}
                        data={venditeAnnualiChart}
                        style={{ height: "40vh" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Paper>
                </div>
              </Container>
            </Grid>
            <Grid container xl={6} md={6}>
              <Container>
                <div>
                  <Grid item xl={12}>
                    <Typography
                      sx={{
                        fontSize: 30,
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#263238"
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Analisi Settimanale
                    </Typography>
                  </Grid>
                  <Paper elevation={3} className={classes.paper}>
                    {show ? (
                      <>
                        <Grid container xl={12} justifyContent="space-around">
                          {settimanale.map((giorno, index) => {
                            return (
                              <Grid direction="row" xl={1.5} item container>
                                <Grid xl={12} item>
                                  <Typography
                                    variant="subtitle1"
                                    fontSize={15}
                                    fontWeight="bold"
                                    textAlign={"center"}
                                  >
                                    {giorno.giorno}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                        <Divider orientation="horizontal"></Divider>
                        <Grid
                          container
                          xl={12}
                          justifyContent="space-around"
                          sx={{ marginTop: "1vh" }}
                          bgcolor="#e3f2fd"
                        >
                          {settimanale.map((giorno, index) => {
                            console.log(giorno);
                            let festivita;
                            if (giorno.festivita !== "") {
                              festivita = <StarIcon fontSize="small" />;
                            }
                            return (
                              <>
                                <Grid direction="row" xl={1.4} item container>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle1"
                                      fontSize={13}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {giorno.data}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={11}
                                      fontWeight="bold"
                                    >
                                      {giorno.festivita}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {formatter.format(giorno.importo)}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      ({giorno.nr_scontrini})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                        <Divider
                          orientation="horizontal"
                          sx={{ marginTop: "2vh" }}
                        ></Divider>
                        <Grid
                          container
                          xl={12}
                          justifyContent="space-around"
                          style={{ marginTop: "1vh" }}
                          bgcolor="#bbdefb"
                        >
                          {settimanalePrecedente.map((giorno, index) => {
                            console.log(giorno);
                            let festivita;
                            if (giorno.festivita !== "") {
                              festivita = <StarIcon fontSize="small" />;
                            }
                            return (
                              <>
                                <Grid
                                  direction="row"
                                  xl={1.4}
                                  item
                                  container
                                  justifyContent="center"
                                  alignContent="center"
                                >
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle1"
                                      fontSize={13}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {giorno.data}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={11}
                                      fontWeight="bold"
                                    >
                                      {giorno.festivita}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {formatter.format(giorno.importo)}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      ({giorno.nr_scontrini})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                        <Divider
                          orientation="horizontal"
                          sx={{ marginTop: "2vh" }}
                        ></Divider>
                        <Grid
                          container
                          xl={12}
                          justifyContent="space-around"
                          style={{ marginTop: "1vh" }}
                          bgcolor="#90caf9"
                        >
                          {settimanaleAnnoPrecedente.map((giorno, index) => {
                            console.log(giorno);
                            let festivita;
                            if (giorno.festivita !== "") {
                              festivita = <StarIcon fontSize="small" />;
                            }
                            return (
                              <>
                                <Grid
                                  direction="row"
                                  xl={1.4}
                                  item
                                  container
                                  justifyContent="center"
                                  alignContent="center"
                                >
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle1"
                                      fontSize={13}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {giorno.data}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={11}
                                      fontWeight="bold"
                                    >
                                      {giorno.festivita}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      {formatter.format(giorno.importo)}
                                    </Typography>
                                  </Grid>
                                  <Grid xl={12} item>
                                    <Typography
                                      variant="body1"
                                      fontSize={15}
                                      fontWeight="bold"
                                      textAlign={"center"}
                                    >
                                      ({giorno.nr_scontrini})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                        <TabContext value={analisiSettimanaleTabValue}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={(event, newValue) => {
                                setAnalisiSettimanaleTabValue(newValue);
                              }}
                              aria-label="lab API tabs example"
                              centered
                            >
                              <Tab label="Close" value="0" />
                              <Tab
                                icon={<Info />}
                                label="Analisi Importi"
                                value="1"
                              />
                              <Tab
                                icon={<Info />}
                                label="Analisi Presenze"
                                value="2"
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <Line
                              options={VenditeSettimanaliOptions}
                              data={venditeSettimanaliChart}
                              style={{ height: "35vh" }}
                            />
                          </TabPanel>
                          <TabPanel value="2">
                            <Line
                              options={PresenzeSettimanaliOptions}
                              data={presenzeSettimanaliChart}
                              style={{ height: "35vh" }}
                            />
                          </TabPanel>
                        </TabContext>
                      </>
                    ) : (
                      <></>
                    )}
                  </Paper>
                </div>
              </Container>
            </Grid>
          </Grid>

          <Grid item xl={6} md={6} className={classes.container}>
            <div style={{ textAlign: "center" }}>
              <Typography
                sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                color="text.secondary"
                gutterBottom
              >
                Obiettivi Settori
              </Typography>

              <Paper elevation={3} className={classes.paper}>
                {show ? (
                  <Grid container xl={12} direction="row" spacing={2}>
                    {Object.entries(settori).map((row, index) => {
                      console.log(row[1]);
                      const settore = row[1];
                      return (
                        <Grid item xl={4}>
                          <CardObiettivoSettore
                            datiSettore={settore}
                          ></CardObiettivoSettore>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <></>
                )}
              </Paper>
            </div>
          </Grid>

          <Grid item xl={6} md={6} className={classes.container}>
            <div>
              <Typography
                sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                color="text.secondary"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                Presenze In Scontrino per Reparto
              </Typography>
              <IconButton variant="outlined" onClick={handleClickOpen}>
                <Info />
              </IconButton>
              <Paper elevation={3} className={classes.paper}>
                {show ? (
                  <DataGrid
                    style={{ flexGrow: 1, height: "41vh" }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    getRowId={(row) => row.descrizione}
                    rows={dataPresenzeReparti}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={itIT.props.MuiDataGrid.localeText}
                    loading={apriSpinner}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "presenze", sort: "desc" }],
                      },
                    }}
                  />
                ) : (
                  <></>
                )}
              </Paper>
            </div>
          </Grid>
          <React.Fragment>
            <Dialog open={open} onClose={handleClose} maxWidth="xl">
              <DialogTitle>Presenze Giornaliere</DialogTitle>
              <DialogContent>
                {show ? (
                  <Bar
                    data={presenzeRepartiGiornalieroChart}
                    options={PresenzeRepartiGiornalieroOptions}
                    className={classes.chart2}
                  />
                ) : (
                  <></>
                )}
              </DialogContent>
            </Dialog>
          </React.Fragment>
        </Grid>
      );
  };

  // Render della pagina
  return (
    <>
    <main className={classes.content}>
      <LoadingSpinner open={apriSpinner} />
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <Navbar
          idCliente={idCliente}
          breadcrumbsReference={BreadcrumbsReference(idCliente)}
        />
        
        {
          HomeRendered()
        }
        {
          configurazioneReport.attivo ? <></> : <Alert severity="error" sx={{ position: "absolute", marginTop: "11vh", marginLeft: "6vw" }}>
            <AlertTitle>Attenzione</AlertTitle>
            La licenza attuale non permette di visualizzare i dati relativi al report Home
          </Alert>

        }

        
      </div>
      
    </main>
    <Myparticles numberAtoms={60}/>
    </>
  );
}

export default VenditeHome;
