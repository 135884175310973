import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";

import columnsDettaglioScontrino from "Utils/Datagrid/Scontrino/DettaglioScontrino";
import NavBar from "Components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { Typography } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import configData from "CONFIGURAZIONE.json";
var server = configData.general_api_end_point;

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "80vw",
  },
}));

function DettaglioScontrino(props) {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [data, setData] = React.useState([]);
  const [fidelity, setFidelity] = React.useState("");
  const [nrOperatore, setNrOperatore] = React.useState("");
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  const [columns] = React.useState(columnsDettaglioScontrino);

  useEffect(() => {
    const data = {
      ven_neg: props.idNegozio,
      ven_dat: props.dataScontrino,
      ven_npo: props.idCassa,
      ven_nsc: props.idScontrino,
    };
    aggiornaCaricamento(true)
    console.log(data);
    axios
      .post(
        `${server}/${props.idCliente}/negozi/${props.idNegozio}/scontrini/cerca/esploso`,
        data
      )
      .then((response) => {
        let rows = [];
        console.log(response.data);
        for (let idx in response.data) {
          console.log(response.data[idx]);
          rows.push(response.data[idx]);
        }
        setData(rows);
        setFidelity(rows[0].ven_card);
        setNrOperatore(rows[0].ven_ope);
        aggiornaCaricamento(false);
      })
      .catch(() => {
        setData([]);
        aggiornaCaricamento(false);
      });
  }, []);

  const ScontrinoRendered = () => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid container xl={8} md={12}>
            <Grid item xl={3} md={2}>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: "bold",
                  padding: theme.spacing(0.3),
                }}
                color="text.secondary"
              >
                Filiale: {props.nrPDV}, Codice: {props.idNegozio}
              </Typography>
            </Grid>
            <Grid item xl={3} md={3}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  padding: theme.spacing(0.3),
                }}
                color="text.secondary"
              >
                Numero Cassa: {props.idCassa}
              </Typography>
            </Grid>
            <Grid item xl={3} md={3}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  padding: theme.spacing(0.3),
                }}
                color="text.secondary"
              >
                Numero Fidelity: {fidelity}
              </Typography>
            </Grid>
            <Grid item xl={3} md={3}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  padding: theme.spacing(0.3),
                }}
                color="text.secondary"
              >
                Operatore: {nrOperatore}
              </Typography>
            </Grid>
            <Grid item xl={3} md={3}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  padding: theme.spacing(0.3),
                }}
                color="text.secondary"
              >
                Numero Scontrino: {props.idScontrino}
              </Typography>
            </Grid>
          </Grid>

          <Box
            className={classes.dataGrid}
            sx={{
              "& .super-app-theme--A3": {
                bgcolor: (theme) =>
                  getBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode
                  ),
                "&:hover": {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.warning.main,
                      theme.palette.mode
                    ),
                },
              },
              "& .super-app-theme--A1": {
                bgcolor: (theme) =>
                  getBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode
                  ),
                "&:hover": {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.warning.main,
                      theme.palette.mode
                    ),
                },
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.ven_idx}
              rows={data}
              columns={columns}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
              loading={caricamentoDati}
              getRowClassName={(params) =>
                `super-app-theme--A${params.row.ven_cau}`
              }
            />
          </Box>
        </Grid>
      </div>
    );
  };

  return <>{ScontrinoRendered()}</>;
}

export default DettaglioScontrino;
